import React, { Component, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { saveAs } from "file-saver";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  auth,
  provider,
  signInWithPopup,
  onAuthStateChanged,
} from "../../Config/firebaseConfig";
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="comingsoon"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <img src="./img/coming.png" />
      </Modal.Body>
    </Modal>
  );
}

const BlackHeader = () => {
  const [user, setUser] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User info:", user);
      setUser(user);

      const response = await fetch(
        "https://api.playappeconomy.com/v1/accounts/googleAuth/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "idToken": user.accessToken,
            "website": "1"
          },
          // body: JSON.stringify({
          //   id: user.uid,
          //   name: user.displayName,
          //   email: user.email,
          //   photo: user.photoURL,
          //   familyName: " ",
          //   givenName: " ",
          //   accessToken: user.accessToken,
          // }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to register user with API");
      }

      const data = await response.json();
      console.log("API response:", data);

      // You can now use the user information for your needs
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const [toggle, setToggle] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    // Update the document title using the browser API
    console.log(toggle);
  }, [toggle]);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("header").classList.add("scroll-sticky");
    } else {
      document.getElementById("header").classList.remove("scroll-sticky");
    }
  }

  const handleDownload = () => {
    // URL of the PDF file you want to download
    const pdfUrl = "./pdf/playapp.pdf";

    // Fetch the PDF file and save it
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "playapp.pdf");
      })
      .catch((error) => {
        console.error("Error downloading the PDF", error);
      });
  };

  return (
    <div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <nav className="navbar navbar-expand-lg FirstNav " id="header">
        <div className="container-fluid" style={{ alignItems: "baseline" }}>
          <Link exact to={"/"} mode="dark">
            <img src="../img/NO.png" style={{ maxWidth: "200px" }} />
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            onClick={() => setToggle(!toggle)}
            data-bs-toggle="collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`navbar-collapse collapse ps-5 padd-left ${
              toggle ? "show" : null
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0  head-data">
              {/* <li className="nav-item">
                <NavLink
                  exact
                  to={"/merchant-account"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Merchant{" "}
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/book-playgrounds"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  How it works{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/about-us"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  About us{" "}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  exact
                  to={"/AI-Sport-Health"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  AI Sports Health
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/find-your-ground"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Venues{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/blog-listing-sports-news"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Blog{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/contact-us"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Contact Us{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to={"/badges"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Badges{""}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  exact
                  to={"/contact-us"}
                  mode="dark"
                  className="nav-link fsnEF"
                >
                  Contact Us{" "}
                </NavLink>
              </li> */}
              <li className="nav-item hover-book">
                {user ? (
                  <button
                    mode="dark"
                    className="nav-link Book fsnEF"
                  >
                    {user.displayName}
                  </button>
                ) : (
                  <button
                    mode="dark"
                    className="nav-link Book hover-book fsnEF"
                    onClick={handleGoogleSignIn}
                  >
                    Register
                  </button>
                )}
                <div className="after-hover arrow_box">
                  <p>
                    After successfully logging in, your Google name will be
                    displayed in this box.
                  </p>
                </div>
              </li>
              <li className="nav-item">
                <button
                  exact
                  mode="dark"
                  onClick={() => setModalShow(true)}
                  className="nav-link Book fsnEF live"
                >
                  <span className="pulse"></span>
                  Live
                </button>
              </li>
            </ul>
            <div className="d-flex align-items-center justify-content-center">
              <a
                style={{ cursor: "pointer" }}
                href="https://play.google.com/store/apps/details?id=com.playapp.economy"
                target="_blank"
              >
                <img
                  src="./img/Download/png-clipart-app-store-google-play-apple-apple-text-logo 2.png"
                  alt=""
                  style={{ maxWidth: "130px" }}
                  className="img-fluid"
                />
              </a>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setModalShow(true)}
              >
                <img
                  src="./img/Download/png-clipart-app-store-google-play-apple-apple-text-logo 1.png"
                  alt=""
                  style={{ maxWidth: "130px" }}
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default BlackHeader;
