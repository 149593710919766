import React from "react";
import CountUp from "react-countup";

export default function Counter({ number, title }) {
  return (
    <div className="number">
      <h1>
        +
        <CountUp duration={10} className="counter" end={number} />
      </h1>
      <h4 className="ms-3">{title}</h4>
    </div>
  );
}
