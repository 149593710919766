import "./Faqbanner.css";
export default function FAQBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>FAQ</h2>
          <h6>
          PlayApp is the Pakistan’s top search and booking site for
            recreational sports.
          </h6>
        </div>
      </section>
    </>
  );
}
