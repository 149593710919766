import React, { useState, useEffect } from "react";

import Lottie from "lottie-react";
import ContactBanner from "./Layout/ContactBanner";
// import ContactUs from  "../../../public/img/Contact-us/ContactUs.json"
// import dinosaurs from '../../../public/img/Contact-us/ContactUs.json' assert {type: 'json'};
import * as animationData from "../../lottieFiles/contactUs.json";
import DocumentMeta from "react-document-meta";
import axios from "axios";
const token =
  "89be9542dee1f653f27f6077c55cd9f64ad8865ee235d91aadc972a175153108e0928c0016787b487cf73b50cd6cfb01d49d176004e999992b9425756ead5b1466c7dd61671c2ef02a77ad887b92640b6a6cecbb16730eb25940f56689934b41f5f0b848db54e49d3b485967bcce61ac4c625329cfc0689d065d0b756be667cd";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length === 0) {
      setShowSuccessMessage(true);
      try {
        const response = await axios.post(
          "https://crm.imining.quantumbases.com/api/play-app-contact-uses",
          {
            data: formData,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has validation errors");
      setErrors(formErrors);
    }
  };

  const validateForm = (formData) => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const currentURL = window.location.href;
  const meta = {
    title: "Contact us | Playapp Support",
    description:
      " Find a help option with Playapp specialist for product information and related queries.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Contact us, playapp support system",
      },
    },
  };
  return (
    <>
      <div>
        {/* contact us */}
        <ContactBanner />
        <section id="contact-us">
          <div className="container">
            <div className="row pb-5 pt-5">
              <div
                className="col-12  col-md-6 col-lg-6 contact-us-left"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h2>Take a step forward and send us a message NOW!.</h2>
                <p>
                  We are here to help you answer any question you might have. We
                  look forward to hearing from you.
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.name}
                    </div>
                  )}
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    placeholder="like: abc@xyzmail.com"
                  />
                  {errors.email && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.email}
                    </div>
                  )}
                  <input
                    placeholder="Enter your phone here"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    type="number"
                  />
                  {errors.phone && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.phone}
                    </div>
                  )}
                  <textarea
                    id="Message"
                    cols={30}
                    rows={10}
                    placeholder="How can we help you?"
                    name="description"
                    defaultValue={""}
                    value={formData.description}
                    onChange={handleChange}
                  />
                   {errors.desc && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.description}
                    </div>
                  )}
                  {showSuccessMessage && (
                    <div className="success-message alert mb-0 w-100 alert-success">
                      Your message has been sent successfully!
                    </div>
                  )}
                  <button className="btn-send" onclick="msgprint()">
                    Send Message
                  </button>
                </form>
              </div>
              <div
                className="col-12 col-md-6 col-lg-6 contact-us-right"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <Lottie
                  style={{ maxWidth: "75%" }}
                  animationData={animationData}
                  loop={true}
                />
                {/* <lottie-player src="./img/Contact-us/lf30_editor_2bgt6tgu.json" background="transparent" speed="0.3" style={{width: '100%', height: 'auto'}} loop autoPlay />  */}
                <h5>Contact Us</h5>
                <ul className="cont p-0">
                  <img
                    className="cont-img"
                    src="../img/Contact-us/icons8-visit-50.png"
                    alt=""
                  />
                  <li className="nav-item mb-2">
                    <a href>23 Dairymaid Rd. Brampton, ON. L6X 5P6 Canada.</a>
                  </li>
                  <br />
                  <img
                    className="cont-img"
                    src="../img/Contact-us/icons8-composing-mail-50.png"
                    alt=""
                  />
                  <li className="nav-item mb-2">
                    <a href>Support@plapappeconomy.conm</a>
                  </li>
                  <br />
                  <img
                    className="cont-img"
                    src="../img/Contact-us/icons8-call-female-24.png"
                    alt=""
                  />
                  <li className="nav-item mb-2">
                    <a href>+1 647-996-4726</a>
                  </li>
                  <div className="icon">
                    {/* <a
                      href="https://www.instagram.com/meidanpk/"
                      target="_blank"
                    >
                      <img src="../img/icon/instagram-white.svg" />
                    </a>
                    <a href="#" target="_blank">
                      <img src="../img/icon/linkedin-white.svg" />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100087793651564"
                      target="_blank"
                    >
                      <img src="../img/icon/facebook-white.svg" />
                    </a>
                    <a
                      href="https://twitter.com/meidanpk?t=PA2XknxiQltBwhbIuoN-TQ&s=08"
                      target="_blank"
                    >
                      <img src="../img/icon/twitter-white.svg" />
                    </a> */}

                    <a
                      href="https://www.instagram.com/playapp_economy/"
                      target="_blank"
                    >
                      <img src="./img/icon/instagram-white.svg" />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=61559774760831"
                      target="_blank"
                    >
                      <img src="./img/icon/facebook-white.svg" />
                    </a>
                    <a
                      href="https://twitter.com/PlayappEconomy"
                      target="_blank"
                    >
                      <img src="./img/icon/twitter-white.svg" />
                    </a>
                    <a
                      href="https://www.tiktok.com/@playappeconomy"
                      target="_blank"
                    >
                      <img
                        style={{ padding: "10px" }}
                        src="./img/Banner/tiktok.png"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCkaBejJQGpGxtyIy2Y3zyIw"
                      target="_blank"
                    >
                      <img
                        style={{ padding: "10px" }}
                        src="./img/Banner/youtube.png"
                      />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ContactUs;
