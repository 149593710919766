import React from "react";
import FAQBanner from "./Layout/FaqBanner";
import Accordion from "react-bootstrap/Accordion";
import faq from "./faq.json";

const Faq = () => {
  console.log(faq);
  return (
    <div>
      <div>
        <FAQBanner />
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Accordion className="accordion w-100" defaultActiveKey="0">
                <div className="d-flex justify-content-center align-items-center gap-3 flex-column">
                  {faq.map((q, i) => (
                    <Accordion.Item
                      className="accordion-item w-100"
                      eventKey={i}
                      key={i}
                    >
                      <Accordion.Header className="acc-head">
                        {q.question}
                      </Accordion.Header>
                      <Accordion.Body>{q.answer}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
