import React, { Component, useEffect, useState } from "react";
import BlogBanner from "../Layout/BlogBanner";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import { getBlog } from "../../../api/Blog/Blog";

const Landing = () => {
  const [getBlogs, setGetBlogs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getBlogListing(currentPage);
    window.scrollTo(0, 0);
  }, [currentPage]);
  const currentURL = window.location.href;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const meta = {
    title: "PlayApp Blog | Stories from the World of Sport",
    description:
      "The PlayApp Blog keeps you up to date with fun and interesting stories from the sporting world. Follow us for articles covering grassroots sports ",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Indoor games for adults, Different types of outdoor games, Best playground equipment for school, Difference between sports and games, 5 indoor sports, different tennis shots",
      },
    },
  };

  const getBlogListing = async (page) => {
    try {
      const response = await getBlog(page);
      const data = response.data;

      console.log("Test", data);
      let array = [...data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      console.log("Bilal This is data of Blog", uniqueArray);
      setGetBlogs(uniqueArray);
      setTotalPages(data.total_page);
    } catch (error) {}
  };

  return (
    <DocumentMeta {...meta}>
      <div>
        <div>
          {/* Blog-listing */}
          <BlogBanner />
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-lg-12">
                <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                  Sports News On PlayApp
                </h1>
                <p className="text-center">
                  Stay up to date with the latest Sports News in the World with the
                  PlayApp Blog! Our blogs are full of exciting and engaging
                  stories from the sports news in the World. The PlayApp Blog brings
                  you a diverse range of content that provides to your love for
                  sports news in the World, ensuring your knowledge about the
                  exciting developments in sports. Don't miss out on the
                  action—stay connected with us for compelling articles that are
                  a complete reflection of sports news in the World.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-5">
              {getBlogs.length > 0 ? (
                getBlogs.map((items) => (
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <Link
                      to={`/blog-detail/${items.slug}`}
                      class="card blog_card p-3"
                      key={items.id}
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <img
                        src={`https://apiblogs.playappeconomy.com/${items.display}`}
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body px-0">
                        <h5 class="card-title">{items.title}</h5>
                        <p class="card-text">
                          {items.parse_body.length > 300
                            ? `${items.parse_body.slice(0, 300)}...`
                            : items.parse_body}
                          &nbsp;
                          <Link
                            className="view-more"
                            to={`/blog-detail/${items.slug}`}
                          >
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                      <div className="shimmer">
                        <div>
                          <label className="line"></label>
                          <h5
                            style={{ minHeight: "200px" }}
                            className="line"
                          ></h5>
                          <div className="">
                            <div className="p-3">
                              <div className="d-flex align-items-center">
                                <p className="line"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="shimmer-anim"></div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous Page
              </button>
              <button className="px-4">{currentPage}</button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next Page
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Landing;
