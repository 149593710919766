import React, { Component } from "react";
import HowItWorkBanner from "./Layout/HowItWorkBanner";
import UserBenefit from "./Layout/UserBenefit";
import Ranks from "./Layout/Ranks";
import DocumentMeta from "react-document-meta";

class HowitWorks extends Component {
  // componentDidMount() {
  //   this.loadMore();
  // }

  // loadMore = () => {
  //   $(document).ready(function () {
  //     $(".content").slice(0, 6).show();
  //     $("#loadMore").on("click", function (e) {
  //       e.preventDefault();
  //       $(".content:hidden").slice(0, 6).slideDown();
  //       if ($(".content:hidden").length == 0) {
  //         $("#loadMore").text("").addClass("noContent");
  //       }
  //     });
  //   });
  // };

  render() {
    const currentURL = window.location.href;
    const meta = {
      title: "Book Playgrounds in World | PLAYAPP",
      description:
        "See how a user can book playgrounds in World. Just enter your location and hit the search button. You’ll have plenty of playgrounds.",
      canonical: currentURL,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Book playgrounds in World, Playgrounds in World, Sports Complex, Largest network of sporting facilities, Sports ground booking, Find playgrounds in World, Book playgrounds in World, Playgrounds near me, nearest snooker clubs, sports complex near me, video game shop, outdoor and indoor games, sports grounds",
        },
      },
    };

    return (
      <DocumentMeta {...meta}>
        <div>
          <HowItWorkBanner />
          <section id="how">
            <div className="container" style={{ overflowX: "hidden" }}>
              <div className="row">
                <div className="col-lg-12">
                  <h2 style={{ fontSize: "40px" }} className="text-center pb-4">
                    The First Incentivized Ecosystem For The Sports And Fitness
                    Community
                  </h2>
                  <p className="text-center">
                    Playapp is the first incentive environment created
                    particularly for the sports and fitness communities. It
                    provides users with a one-of-a-kind platform where they may
                    earn online prizes for participating in and completing
                    various physical activities. Users earn points for engaging
                    in challenges, completing exercises, and keeping active,
                    which they can then redeem for exciting rewards such as
                    match tickets, fitness gear, and more. The unique approach
                    not only encourages people to live a healthier lifestyle but
                    also promotes a dynamic, engaged community of fitness
                    aficionados. Staying active is made more enjoyable and
                    rewarding with Playapp.
                  </p>
                  <p className="text-center">
                    There's no need to worry about finding the best locations
                    for your favorite activities. With PlayApp, discovering
                    playgrounds globally is simple and efficient. Whether you're
                    planning an energetic game with friends or a solo workout,
                    the process is smooth and straightforward. Just enter your
                    location, press the search button, and you'll be taken to a
                    world of options to find playgrounds anywhere. Plus, you can
                    earn rewards by reviewing venues, checking in, unlocking
                    badges, and ranking up. Stay tuned for our exciting booking
                    and live streaming features, coming soon
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6" id="howToImageContainer">
                  <img
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    className="how-img find_Playgrounds"
                    style={{ width: "100%" }}
                    src="./img/FindPlaygrounds.svg"
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-6 how-text"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  {/* This is the table */}
                  <h3>Earn Online Scores</h3>
                  <p>
                    Earn scores and get ranked with the help of Playapp. Here is
                    the display of the score system in depth.
                  </p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th></th>

                        <th>Scores System</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Registration </td>
                        <td></td>
                        <td className="text-end">10 points</td>
                      </tr>

                      <tr>
                        <td>Referring a friend</td>
                        <td></td>
                        <td className="text-end">20 points </td>
                      </tr>
                      <tr>
                        <td>Being referred by a friend (referral)</td>
                        <td></td>
                        <td className="text-end">10 points</td>
                      </tr>
                      <tr>
                        <td>Writing a review </td>
                        <td></td>
                        <td className="text-end">2 points</td>
                      </tr>
                      <tr>
                        <td>Checking in</td>
                        <td></td>
                        <td className="text-end">5 points</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 2st book */}

                <Ranks />

                {/* <div className="row mt-5">
                  <div
                    className="col-lg-12 how-text-table order-2 order-lg-1"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h3 className="text-center">Ranks Based on Scores</h3>
                    <p className="text-center">
                      Users will be awarded with Ranks based on the scores (earn
                      online) system given above. Here are the complete rank
                      system
                    </p>
                    <h2 className="text-center" style={{ fontSize: "25px" }}>
                      Rank
                    </h2>
                    <div className="table-responsive">
                      <table class="table w-100">
                        <table className="text-center w-100"></table>
                        <tbody>
                          <tr>
                            <td>Newbie </td>
                            <td style={{ width: "60%" }}>
                              You lace up your shoes and step out into the
                              unknown, eager to explore new venues and discover
                              hidden gems. Every check-in and review brings you
                              closer to unlocking the secrets of the sports and
                              fitness community.
                            </td>
                            <td>1x</td>
                            <td>0-499 scores</td>
                          </tr>
                          <tr>
                            <td>Apprentice </td>
                            <td style={{ width: "60%" }}>
                              You stumble upon a trendy fitness studio, and the
                              instructor shares tips and tricks to enhance your
                              workout. You realize that every venue has a story
                              to tell, and every review helps others find their
                              new favorite spot.
                            </td>
                            <td>1.25x</td>
                            <td>500-999 scores</td>
                          </tr>
                          <tr>
                            <td>Rookie </td>
                            <td style={{ width: "60%" }}>
                              You conquer a challenging hike, and the
                              breathtaking view from the summit is your reward.
                              You can't wait to share your experience and help
                              others find their next adventure.
                            </td>
                            <td>1.5x</td>
                            <td>999-1999 scores</td>
                          </tr>
                          <tr>
                            <td>Dynamo</td>
                            <td style={{ width: "60%" }}>
                              You're a fitness force to be reckoned with,
                              exploring new venues and trying new activities.
                              Every check-in and review earns you scores, and
                              you're determined to reach the top of the
                              leaderboard.
                            </td>
                            <td>1.75x</td>
                            <td>2000-3999 scores</td>
                          </tr>

                          <tr>
                            <td>Maestro</td>
                            <td style={{ width: "60%" }}>
                              You're a seasoned explorer, with a wealth of
                              knowledge and experience. You've discovered hidden
                              gems and shared your expertise with the community.
                              Your reviews are sought after, and your scores are
                              the envy of many.
                            </td>
                            <td>2x</td>
                            <td>4000-9999 scores</td>
                          </tr>

                          <tr>
                            <td>Elite</td>
                            <td style={{ width: "60%" }}>
                              You're a legend in the sports and fitness
                              community, with a reputation for discovering the
                              best venues and sharing your expertise. Your name
                              is synonymous with adventure and fitness, and your
                              scores are the stuff of legend
                            </td>
                            <td>2.5x</td>
                            <td>10,000-49,999 scores</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* 3st find */}
              {/* <div className="row mt-5">
                <div className="col-lg-6" id="howToImageContainer">
                  <img
                    className="how-img find_Playgrounds"
                    src="./img/YoucanPlay.svg"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="col-lg-6 how-text"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h2>You can Play</h2>
                  <p>
                    {" "}
                    You're the hero; you easily discovered and reserved a
                    gorgeous pitch or court, and it's time to start playing. The
                    stage is prepared for your momentous game, in which hopes
                    might be made or broken. Suffering is just momentary, while
                    triumph is eternal.
                  </p>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-12 justify-content-center  text-center">
                  <a
                    className="fill  "
                    href="https://play.google.com/store/apps/details?id=com.meidanuserapp"
                    target="_blank"
                  >
                    <button>Download app Now</button>
                  </a>
                </div>
              </div> */}
              {/* modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Comming Soon
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      PlayApp application will be available on App Store &amp;
                      Google Play Store Soon.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* benefits */}
          {/* <UserBenefit /> */}
          <section>
            <div className="container">
              <div className="row text-center justify-content-center pt-5">
                <h2>PlayApp Features</h2>
                <img
                  src="../img/Group320.png"
                  alt=""
                  style={{ width: "85%" }}
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </section>
        </div>
      </DocumentMeta>
    );
  }
}

export default HowitWorks;
