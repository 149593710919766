import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          style={{ width: "100%", height: "300px" }}
          src="https://www.youtube.com/embed/SrF-wWIm1cQ?si=TWL_ICmwCQtZxovl"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

function VideoModal() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        className="pt-5 pb-5 text-center"
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => setModalShow(true)}
      >
        <img src="../../../img/play.png" alt="play" />
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default VideoModal;
