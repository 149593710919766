import React from "react";

const AiSportsHealth = () => {
  return (
    <div>
      <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>AI Support</h2>
          {/* <h6>Page of badges</h6> */}
        </div>
      </section>
      <section id="how">
        <div className="container" style={{ overflowX: "hidden" }}>
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                AI Sports Health
              </h1>
              <p className="text-center">
                AI Sports health is the new era of athletic success. In addition
                to its core features, PlayApp offers a suite of innovative AI
                sports health to support your overall well-being and sports
                performance.
              </p>
              <div>
                <ol>
                  <li className="heading-primary">
                    {" "}
                    <h5>AI - Trainer</h5>
                    <ul>
                      
                      <li>
                       <h6> Real time feedback and corrective guidance</h6>
                        <p>
                          Receive instant, real-time feedback and precise
                          guidance to boost your performance and achieve your
                          fitness goals with our innovative technology and
                          expert coaching
                        </p>
                      </li>
                      <li>
                       <h6> Extensive Exercise Video Library</h6>
                        <p>
                          Comprehensive collection of workout videos of various
                          fitness disciplines and levels. With a wide range of
                          workouts, from yoga and aerobics to strength training.
                          This library provides a resource to achieve fitness
                          goals.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>AI - Psychologist </h5>
                    <p>
                      PlayApp’s AI-Psychologist helps players and athletes
                      develop the mental skills to improve game. The AI guides
                      athletes to strengthen mental toughness to win. This
                      feature is currently under testing and is showing signs of
                      positive results. After being made available, PlayApp
                      users will earn scores and unlock rewards to consult with
                      the AI-Phycologist.{" "}
                    </p>
                  </li>
                  <li>
                   <h5> AI - Doctor</h5>
                    <p>
                      {" "}
                      PlayApp’s AI doctor is a consultation and treatment
                      assistance tool currently under testing, against highly
                      experienced human doctors from across the world. It is
                      still under beta testing. When it will be launched PlayApp
                      users will be rewarded to go-to the doctor with scores and
                      amazing prizes.
                    </p>
                  </li>
                  <li>
                   <h5> AI - Sports Coach</h5>
                    <p>
                      The future of performance analysis is changing with
                      PlayApp’s “AI - Coach”. It will alter evaluation criteria
                      of players as well as injury prevention, and customized
                      training. Now Players and their coaches will have access
                      to an abundance of information to create the perfect
                      training methods and high-sports performance.
                    </p>
                  </li>
                  <li>
                  <h5>  AI - Physio</h5>
                    <ul>
                      <li>
                        The use of AI in physiotherapy is a new concept, but it
                        holds the potential to revolutionize the way we treat
                        people with physical ailments. PlayApp’s AI-Physio could
                        help streamline treatment plans based on real-time data
                        and improve outcomes for patients by providing real-time
                        feedback and corrective guidance on how their bodies
                        move while earning scores and unlocking rewards.
                      </li>
                      <li>
                        PlayApp’s AI PHYSIO is taking the underlying technology
                        of motion tracking to new heights using computer vision
                        for human motion analysis. This technology can
                        accurately capture movement patterns in real-time, while
                        performing musculoskeletal physiotherapy exercises and
                        without needing any physical contact with the patient,
                        providing insights that could lead to more effective
                        treatments while also improving efficiency at clinics
                        across the world, while earning scores and unlocking
                        rewards
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>AI - Nutritionist</h5>
                    <p>
                      Get answers to all your nutrition related questions. We
                      want everyone to have their own personal nutritionist.
                      With PlayApp’s A-Nutritionist, you gain access to a set of
                      features designed to provide you with personalized
                      nutrition guidance, expert support, and a wealth of
                      educational resources.
                    </p>
                    <ul>
                      <li>
                        {" "}
                       <h6> AI-Powered Chat:{" "}</h6>
                        <p>
                          Get instant answers to nutrition related questions.
                          It’s like having your own personal nutritionist at
                          your fingertips
                        </p>
                      </li>
                      <li>
                        {" "}
                        <h6> Nutrition Experts:{" "}</h6>
                        <p>
                          {" "}
                          Get specific support around topics like Weight Loss,
                          Meal Planning, Health & Wellness and Diabetes.{" "}
                        </p>
                      </li>
                      <li>
                        {" "}
                        <h6>Informative Microblogs:{" "}</h6>
                        <p>
                          Dive into our library of microblogs and discover
                          bite-sized educational content on a wide range of
                          nutrition topics, generate meal recipes and much more.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
                <div className="text-center">
                  {" "}
                  <b>
                    This way we can help AI sports health to revolutionize the
                    health and sports industry.{" "}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AiSportsHealth;
