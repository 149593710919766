import React, { useState, useMemo } from "react";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { FaGoogle } from "react-icons/fa";

const GoogleAuth = () => {
  const clientId =
    "748607149384-ikt4qn2lqv8omacg6j8nrrq66o1h9p7u.apps.googleusercontent.com";

  const [credentialResponse, setCredentialResponse] = useState("");

  const user = useMemo(() => {
    if (!credentialResponse?.credential) return;
    return jwtDecode(credentialResponse.credential);
  }, [credentialResponse]);

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <button className="google-btn" onClick={login}>
        <FaGoogle />
        <p className="my-0">Continue with google</p>
      </button>
      {/* <GoogleLogin
        theme="filled_black"
        shape="circle"
        onSuccess={(credentialResponse) => {
          console.log("pass", credentialResponse);
          setCredentialResponse(credentialResponse);
        }}
        onError={(e) => {
          console.log("Login Failed", e);
        }}
      /> */}
    </>
  );
};

export default GoogleAuth;
