import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  auth,
  provider,
  signInWithPopup,
  onAuthStateChanged,
} from "../../Config/firebaseConfig";
import { FaGoogle } from "react-icons/fa";

const Footer = () => {
  const [user, setUser] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User info:", user);
      setUser(user);

      const response = await fetch(
        "https://api.playappeconomy.com/v1/accounts/googleAuth/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": user.accessToken,
            "website": "1"
          },
          // body: JSON.stringify({
          //   id: user.uid,
          //   name: user.displayName,
          //   email: user.email,
          //   photo: user.photoURL,
          //   familyName: " ",
          //   givenName: " ",
          //   accessToken: user.accessToken,
          // }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to register user with API");
      }

      const data = await response.json();
      console.log("API response:", data);

      // You can now use the user information for your needs
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  return (
    <div>
      <div className="ExploreFooter">
        <div className="container">
          <div className="row align-items-center">
            <div class="col-lg-2">
              <div class="explore">
                <Link to="">
                  Updated News
                  <div class="arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-lg-10">
              <div class="footer-join-wrapper">
                <p className="m-0">
                  <marquee direction="right" scrollamount="10">
                    Join our network and grow your business
                  </marquee>
                </p>
                {/* <a href="/partnership-form">Partner With Us</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <img
                className="gap_down"
                src="../../../img/Frame 137.png"
                alt="logo"
              />
              {/* <label htmlFor="">Disclaimer:</label> */}
              <p className="para_gap justify-content">
                PlayApp helps you discover new places and make friends while
                earning prizes and recognition. Additionally, it lets you watch
                the best live streams of any match, offering even more
                opportunities to win prizes!{" "}
              </p>
            </div>
            {/* <span className="footer_under" > */}
            <div className="col-lg-5">
              <div className="row">
                <div className="col-6 ">
                  <label htmlFor="">Help & Support</label>
                  {/* <p>Consider us the Pakistan’s largest indoor and outdoor digital sports club where you may make reservations on demand from any location at no additional charges.</p> */}
                  <ul>
                    <Link exact to={"/faq"} mode="dark" className="cus-link">
                      <li className="">FAQ's </li>
                    </Link>
                    <Link
                      exact
                      to={"/contact-us"}
                      mode="dark"
                      className="cus-link"
                    >
                      <li className="">Contact Us </li>
                    </Link>
                    <Link
                      exact
                      to={"/blog-listing-sports-news"}
                      mode="dark"
                      className="cus-link"
                    >
                      <li className="">Blogs </li>
                    </Link>
                    <Link exact to={"/badges"} mode="dark" className="cus-link">
                      <li className="">Badges </li>
                    </Link>
                    {/* <li>Careers</li> */}
                    {/* <li>Partnerships</li> */}
                    {/* <li>Testimonial</li> */}
                  </ul>
                </div>
                <div className="col-6 ">
                  <label htmlFor="">Legal</label>
                  <ul>
                    <Link
                      exact
                      to={"/about-us"}
                      mode="dark"
                      className="cus-link"
                    >
                      <li className="">About Us </li>
                    </Link>
                    {/* <li>Privacy Policy</li> */}
                    <Link
                      exact
                      to={"/pricing-refund"}
                      mode="dark"
                      className="cus-link"
                    >
                      <li className="">Privacy Policy </li>
                    </Link>
                    <Link
                      exact
                      to={"/terms-and-conditions"}
                      mode="dark"
                      className="cus-link"
                    >
                      <li className="">Terms and Conditions </li>
                    </Link>

                    {/* <Link
                  exact
                  to={"/Sign-up"}
                  mode="dark"
                  className="cus-link"
                >
                <li>Sign up</li>
                </Link> */}
                  </ul>
                  {/* <ul>
                <li>How It Works</li>
                <li>Terms and Condition</li>
                <li>Blog</li>
                <li>Contact Us</li>
              </ul> */}
                </div>
              </div>
            </div>
            {/* </span> */}
            {/* <div className="col-lg-3">
              <label htmlFor="">Product</label>
              <ul>
                <li>Project managment</li>
                <li>Time tracker</li>
                <li>Time schedule</li>
                <li>Lead generate</li>
                <li>Remote Collaboration</li>
              </ul>
            </div> */}
            <div className="col-lg-3">
              {/* <img src="../../../img/Frame 137.png" alt="logo" /> */}
              <h2 className="">Subscribe to our Newsletter</h2>
              <p className="para_gap justify-content">
                To subscribe our newsletter you need to login with Google Auth
              </p>
              <div class="input-group group-gr mb-3 p-0">
                {user ? (
                  <div class="input-group-append">
                    <button
                      onClick={handleGoogleSignIn}
                      class="btn btn-outline-secondary m-0"
                      type="button"
                      disabled
                    >
                      &nbsp; {user.email}
                    </button>
                  </div>
                ) : (
                  <div class="input-group-append">
                    <button
                      onClick={handleGoogleSignIn}
                      class="btn btn-outline-secondary m-0"
                      type="button"
                    >
                      <FaGoogle />
                      &nbsp; Subscribe
                    </button>
                  </div>
                )}

                {/* <div class="input-group-append">
                  <button
                    onClick={handleGoogleSignIn}
                    class="btn btn-outline-secondary m-0"
                    type="button"
                  >
                    <FaGoogle />
                    &nbsp; Subscribe
                  </button>
                </div> */}
              </div>

              {/* <div className="d-flex justify-content-end group-gr">
                <div className="input-group">
                  <input type="search" placeholder="Enter your Email" />
                </div>
                <button className="pos-init"
                  style={{
                    paddingLeft: 25,
                    paddingRight: 25,
                    paddingTop: 15.8,
                    paddingBottom: 15.8,
                    background: "white",
                    borderRadius: 12.6,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 10.5,
                    display: "inline-flex",
                    color: "#CD1127",
                    fontSize: 16.8,
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    lineHeight: "25.20px",
                    border: "none",
                    position: "absolute",
                  }}
                >
                  Subscribe
                </button>
              </div> */}
            </div>
          </div>
          <div className="row">
            <hr class="white" />
            <div class="row text-center">
              <div class="col">
                <p class="white m-0" style={{ fontSize: "12px" }}>
                  © 2024 PLAYAPP TECHNOLOGIES LIMITED ALL RIGHTS
                  RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
