import React, { useEffect, useState } from "react";
import axios from "axios";
import VanueBanner from "./Layout/VanueBanner";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import {
  auth,
  provider,
  signInWithPopup,
  onAuthStateChanged,
} from "../../Config/firebaseConfig";
export default function AvanueList() {
  const [groundData, setGroundData] = useState([]);
  const [searchData, setSearchData] = useState({
    search: "",
  });

  const currentURL = window.location.href;

  const meta = {
    title: "Sportsgrounds Review | Playapp Venue Complete Guide",
    description:
      "Playapp Sportsgrounds reviews has the most accurate reviews of different sportsgrounds. Take advantage of these reviews and earn scores,badges and gifts.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords: " sportsgrounds reviews, ground news review ",
      },
    },
  };

  useEffect(() => {
    getSponsorGround();
  }, []);

  const inputSearch = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    getListingGround();
  };

  const getSponsorGround = async () => {
    try {
      const sponserResponse = await axios.get(
        "https://api.PlayApp.pk/v1/ground-profiles/list/sponsor/"
      );
      const listingresponse = await axios.get(
        "https://api.PlayApp.pk/v1/ground-profiles/list/top-rated/?page=1"
      );
      let array = [...sponserResponse.data.data, ...listingresponse.data.data];
      console.log("sponserResponse", sponserResponse.data.data);
      console.log("listingresponse", listingresponse.data.data);
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setGroundData(uniqueArray);
    } catch (error) {
      console.log(error);
    }
  };

  const getListingGround = async (value) => {
    try {
      const searchAny = await axios.get(
        `https://api.PlayApp.pk/v1/searches/?page=1&q=${searchData.search}`
      );
      setGroundData(searchAny.data.data);
    } catch (error) {}
  };








  const [user, setUser] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setUser(user);

      // Send user data to your API
      const response = await fetch('https://your-api-endpoint.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to register user with API');
      }

      const data = await response.json();
      console.log('API response:', data);

    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <DocumentMeta {...meta}>
        <VanueBanner />
        <div className="container pt-5 pb-3">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Sports Grounds Review
              </h1>

              <p className="text-center">
                Sports Grounds Reviews can help users earn scores. Playapp is
                transforming their experience into an engaging and rewarding
                activity. Users can earn points and scores by submitting their
                feedback on different sportsgrounds. These points and scores
                serve as gifts to encourage users to explore more venues and
                share their insights. Sportsgrounds reviews motivate users to
                actively participate in the sports venues. This creates a
                vibrant and interactive platform for sports lovers.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Review, Earn Scores, and Unlock Badges
              </h1>
              <p className="text-center">
                Sports Grounds reviews offer users an exciting way to engage
                with the app. By sharing reviews of sportsgrounds, users can
                collect scores and badges to recognize their contributions and
                achievements. This activity makes it more exciting, rewarding
                and continuous participation. The badges are a visual
                representation of users' accomplishments and motivation to
                explore and review more venues.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row pt-5">
            <div
              className="col-lg-10 offset-lg-1
            "
            >
              <div class="row height d-flex justify-content-center align-items-center">
                <div class="col-md-8">
                  {user ? (
                    <div class="search d-flex justify-content-center">
                      <div className="w-100">
                        <img
                          src="./img/loupe.png"
                          alt=""
                          className="fa fa-search"
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search Venues"
                          onChange={inputSearch}
                          value={searchData.search}
                          name="search"
                        />
                      </div>
                      <div>
                        <button class="btn m-0 btn-primary">Search</button>
                      </div>
                    </div>
                  ) : (
                    <div class="search d-flex justify-content-center">
                      <div className="w-100">
                        <img
                          src="./img/loupe.png"
                          alt=""
                          className="fa fa-search"
                        />
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          placeholder="Please Register First Then Search"
                        />
                      </div>
                      <div>
                        <button disabled class="btn m-0 btn-primary">Search</button>
                      </div>
                    </div>
                  )}

                  {/* <div class="search d-flex justify-content-center">
                    <div className="w-100">
                      <img
                        src="./img/loupe.png"
                        alt=""
                        className="fa fa-search"
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Venues"
                        onChange={inputSearch}
                        value={searchData.search}
                        name="search"
                      />
                    </div>
                    <div>
                      <button class="btn m-0 btn-primary">Search</button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row pt-5">
                {groundData.length === 0 ? (
                  <>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <div className="col-lg-4 mb-4">
                        <div className="shimmer">
                          <div>
                            <label className="line"></label>
                            <h5
                              style={{ minHeight: "200px" }}
                              className="line"
                            ></h5>
                            <div className="">
                              <div className="p-3">
                                <div className="d-flex align-items-center">
                                  <p className="line"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="shimmer-anim"></div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  groundData.map((item) => (
                    <div className="col-lg-4" key={item.id}>
                      {/* <Link
                        to={{
                          pathname: "/venues-detail",
                          state: { groundDetail: item },
                        }}
                      > */}
                      <div>
                        <div className="product">
                          <figure className="product-media">
                            <a href>
                              <img src={item.background} alt="Category" />
                            </a>
                            <div className="product-label-group">
                              <label className="product-label label-new ">
                                Hot
                              </label>
                            </div>
                          </figure>
                          <div className="d-flex aligns-items-center justify-content-between">
                            <div className="p-3">
                              <h5 className="text-black ">{item.name}</h5>
                              <p>
                                {item?.address?.city}, {item?.address?.state},{" "}
                                {item?.address?.country}
                              </p>
                              <p>{item.games}</p>
                            </div>
                            <div className="p-3">
                              <div className="d-flex align-items-center">
                                <img src="../img/star.svg" alt="" />
                                <p className="text-black">
                                  {item.average_rating}
                                </p>
                              </div>
                            </div>
                          </div>
                          <a
                            className="book-now position"
                            href="https://play.google.com/store/apps/details?id=com.PlayAppuserapp"
                            target="_blank"
                          >
                            Review Now
                          </a>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </>
  );
}
