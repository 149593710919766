import invoke from "../../utils/invoke";
import { Config } from "../../Config/Config";


export const getBadges = (page) => {
    return invoke({
      method: "GET",
      baseURL: Config.playAppbaseuUrl,
      route: `v1/ranks/platform-badges`,
    });
  };