export default function HowItWorkBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center HowBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h1 style={{ color: "white" }}>How It Works</h1>
          <h6>Sports - You See This Name, You Think PlayApp.</h6>
        </div>
      </section>
    </>
  );
}
