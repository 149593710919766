import React from "react";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import GoogleAuth from "./Component/GoogleAuth";
const Signup = () => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    job: "",
    password: "",
    passwordConfirmation: "",
  });
  const [key, setKey] = useState("Sign Up");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (key === "Sign Up") {
      // Handle sign up form submission
      console.log("signUp Form submitted:", formState);
    } else {
      // Handle sign in form submission
      console.log("signIn Form submitted:", formState);
    }
  };
  return (
    <>
      <div className="container mt-5">
        <div className="row py-5 mt-4 align-items-center justify-content-center">
          <div
            className="col-md-7 col-lg-6 ml-auto mt-5"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              id="controlled-tab-example"
            >
              <Tab eventKey="Sign Up" title="Sign Up">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        First Name
                      </label>
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="Enter Your First Name"
                        className="form-control"
                        value={formState.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Enter Your Last Name"
                        className="form-control"
                        value={formState.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className="form-control"
                        value={formState.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        Password
                      </label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Type in a password"
                        className="form-control"
                        value={formState.password}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="d-flex justify-content-start align-item-center gap-3 sign-terms">
                      <input type="checkbox" name="" id="" />
                      <p className="mb-0 mt-0">Terms of Services</p>
                    </div>
                    <div className="sign-btn">
                      <button className="">Sign Up</button>
                    </div>
                    <div className="mt-3">
                      <p>
                        Already a Member?
                        <a
                          href="#"
                          className="ms-2"
                          style={{ textDecoration: "underline" }}
                          onClick={(k) => setKey("Sign In")}
                        >
                          Sign In
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </Tab>
              <Tab eventKey="Sign In" title="Sign In">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className="form-control"
                        value={formState.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="sign-gr row">
                      <label htmlFor="" className="label_data">
                        Password
                      </label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Type in a password"
                        className="form-control"
                        value={formState.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="sign-btn">
                      <button className="">Sign In</button>
                    </div>
                    <div className="mt-3">
                      <p>
                        Dont have an account?
                        <a
                          href="#"
                          className="ms-2"
                          style={{ textDecoration: "underline" }}
                          onClick={(k) => setKey("Sign Up")}
                        >
                          Sign Up
                        </a>
                      </p>
                    </div>
                  </div>
                </form>{" "}
              </Tab>
            </Tabs>

            <GoogleAuth />
          </div>
          <div
            className="col-md-5 pr-lg-5 mb-5 mb-md-0 "
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <img
              src="../img/signUP.png"
              alt=""
              className="img-fluid mb-3 d-none d-md-block"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
