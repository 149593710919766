import React from "react";
import "./badge.css";

const BadgeComponent = ({ name, img, dec, reward }) => {
  return (
    <>
      <div className="text-center mt-3">
        <div className="badge-con">
          <img src={img} alt="" />
          <h4 className="mt-3">{name}</h4>
          <p className="mt-2">{dec}</p>
          <h5>+{reward}</h5>
        </div>
      </div>
    </>
  );
};

export default BadgeComponent;
