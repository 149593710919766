import React from "react";

const Offer = () => {
  return (
    // <div className="Offer" style={{ background: "black" }}>
    //   <div className="container">
    //     Offer
    //   </div>
    // </div>

    <section className="offer-banner-wrapper">
      <div className="container">
        <div className="offer-banner-inner">
          <div className="row">
            <div className="col-lg-5">
              <div className="offer-banner-part1">
                <div className="offer-banner-img">
                  <img
                    src="./img/ManualMerchantApp.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 offer-banner-part2-whole-wrapper">
              <div className="offer-banner-part2">
                <div className="offer-banner-text2">
                <div className="offer-banner-text1">
                  <h6>Get your</h6>
                  <h5>Advantage</h5>
                  <p>
                    in the <span>App</span>.
                  </p>
                </div>
                  <h5>
                    <span>30</span> SCORES
                  </h5>
                  <p>on your each refferal.</p>
                </div>
                <div className="offer-divider" />
                <div className="offer-banner-qr-img">
                  <img
                    src="./img/qr.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offer;
