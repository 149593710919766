import React, { Component, useEffect, useState } from "react";
import BadgeComponent from "./Layout/BadgeComponent";
import badge from "./badge.json";
import { getBadges } from "../../api/Badges/Badges";
import DocumentMeta from "react-document-meta";

const Badges = () => {
  const currentURL = window.location.href;
  const [getBadgesList, setGetBadges] = useState([]);


  useEffect(() => {
    getBadgesListing();
  }, []);

  const getBadgesListing = async () => {
    try {
      const response = await getBadges();
      const data = response.data;

      console.log("Test", data);
      let array = [...data.data];
      const uniqueArray = array.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );
      console.log("Bilal This is data of Badges", uniqueArray);
      setGetBadges(uniqueArray);
    } catch (error) {}
  };

  const meta = {
    title: "How to Earn Badges on Playapp",
    description:
      "Here is the detail to earn badges on playapp. The easy and complete badge system to understand the process of earning badges.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          " how to earn badges, how to earn badges on playapp, badges system",
      },
    },
  };
  return (
    <div>
      <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>Badges</h2>
          <h6>
            Badges are visual representations of achievements or milestones
            reached by users.
          </h6>
        </div>
      </section>
      <div className="container">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div
              className="col-lg-12"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h1 style={{ fontSize: "40px" }} className="text-center pb-4">
                Unlock Badges on Playapp
              </h1>
              <p className="text-center mb-0">
                The more you play and visit sporting facilities, the higher your
                score and the more badges and awards you can earn. Rewards are
                integrated into all aspects of PlayApp. As you unlock badges and
                rank up, you accumulate even more scores. You can also earn
                coupons and prizes such as tickets to sports games, a golf kit,
                and gift certificates to popular stores. Earning badges and
                ranking up has never been easier. Our high-quality service
                honors users by awarding badges based on the criteria outlined
                below. You may also earn badges and obtain coupons and prizes
                such as Cricket World Cup tickets, tickets to football,
                basketball, or hockey games, a golf kit, a gift certificate to
                Golf Town, Sport Chek, E-Games, and much more! Earning badges is
                not difficult anymore. Our high quality service honors users by
                giving Badges according to the given below sheet.
              </p>

              <div>
                {/* <table
                  class="table "
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <thead>
                    <tr>
                      <th className="col-3"></th>

                      <th className="col-3 text-center">
                        Learn to Earn Badge System
                      </th>
                      <th className="col-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Welcome Badge </td>
                      <td></td>
                      <td className="text-end">On Sign Up City Badge</td>
                    </tr>

                    <tr>
                      <td>City Badge </td>
                      <td></td>
                      <td className="text-end">100 Score </td>
                    </tr>
                    <tr>
                      <td>Country Badge </td>
                      <td></td>
                      <td className="text-end">On Sign Up Country Badge</td>
                    </tr>
                    <tr>
                      <td>Check-In Badge </td>
                      <td></td>
                      <td className="text-end">30 Score </td>
                    </tr>
                    <tr>
                      <td>Cheick-In</td>
                      <td></td>
                      <td className="text-end">On 1st CHECK-IN</td>
                    </tr>
                    <tr>
                      <td>Review Badge </td>
                      <td></td>
                      <td className="text-end">On 1st Review </td>
                    </tr>
                    <tr>
                      <td>Referral Badge</td>
                      <td></td>
                      <td className="text-end">On 1st Friend Referral </td>
                    </tr>
                    <tr>
                      <td>Social Badge </td>
                      <td></td>
                      <td className="text-end">
                        1.Share on Social Media 1st Venue{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Social Badge </td>
                      <td></td>
                      <td className="text-end">
                        1.Share on Social Media 1st Venue{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Friendship Badges </td>
                      <td></td>
                      <td className="text-end">Referring 10 Friends </td>
                    </tr>
                    <tr>
                      <td>Social Badge </td>
                      <td></td>
                      <td className="text-end">
                        1.Share on Social Media 1st Venue{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Activity Badges </td>
                      <td></td>
                      <td className="text-end">Reviewing 10 Venues</td>
                    </tr>
                    <tr>
                      <td>Venue Badge </td>
                      <td></td>
                      <td className="text-end">Check-IN to 10 Venues</td>
                    </tr>
                    <tr>
                      <td>Punctual Badges </td>
                      <td></td>
                      <td className="text-end">
                        Login to APP 10 days in a Row
                      </td>
                    </tr>
                    <tr>
                      <td>Mayor of Venue Badges </td>
                      <td></td>
                      <td className="text-end">
                        On Check-in Venue for 30 days in a Row
                      </td>
                    </tr>
                    <tr>
                      <td>Special Badges </td>
                      <td></td>
                      <td className="text-end">On Weekly Surprises</td>
                    </tr>
                    <tr>
                      <td>Sponsored Badges </td>
                      <td></td>
                      <td className="text-end">Offered upon sponsorship</td>
                    </tr>
                    <tr>
                      <td>Premium Mestro Badge </td>
                      <td></td>
                      <td className="text-end">
                        Monthly paying of $7 Dollars to get 2.5x the scores
                      </td>
                    </tr>
                    <tr>
                      <td>Leader Badge </td>
                      <td></td>
                      <td className="text-end">
                        Upon Achieving the Highest scorer for the Week on the
                        Leaderboard
                      </td>
                    </tr>
                  </tbody>
                </table> */}

                <h1
                  style={{ fontSize: "40px" }}
                  className="text-center pb-4 mt-4"
                >
                  Play to Earn Badge System
                </h1>

                <div className="row">
                  {getBadgesList.map((item) => (
                    <div className="col-md-6 col-lg-4"                 
                    data-aos="fade-up"
                    data-aos-duration="1500">
                      <BadgeComponent
                        name={item.title}
                        img={item.image}
                        dec={item.requirement_note}
                        reward={item.badge_scores}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row service-11">
            <div className="col-12">
              <hr
                className="my-5"
                style={{ color: "green", height: "3px" }}
              ></hr>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 wrap-service11-box">
              <div
                className="card benefitCard card-shadow border-0 mb-4"
                style={{ overflow: "unset" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className="p-4">
                  <div className="icon-space">
                    <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                      <img
                        className="icon-d"
                        src="./img/become-a-merchant/1.svg"
                        alt=""
                        style={{ paddingBottom: "14px" }}
                      />
                    </div>
                  </div>
                  <h6 className="">Prizes Distribution Method</h6>
                  <p className="mt-3">
                    To unlock a gift, users must first earn the required points.
                    Each award has a limitation on how many times it may be
                    unlocked. Once this limit is reached, no other users will be
                    able to acquire that reward, even if they have the necessary
                    points.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 wrap-service11-box">
              <div
                className="card benefitCard card-shadow border-0 mb-4"
                style={{ overflow: "unset" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className="p-4">
                <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="./img/become-a-merchant/2.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                  <h6 className="">Prize Pool</h6>
                  <p className="mt-3">
                    We are introducing a new interesting feature: a prize pool
                    for match tickets. There is one ticket available for users
                    to unlock with a certain quantity of points. If more than
                    one user wants the ticket, Playapp will conduct a lucky
                    draw. The lucky user will win the ticket, while the others
                    will give up both the ticket and the points they used to
                    unlock it
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 wrap-service11-box">
              <div
                className="card benefitCard card-shadow border-0 mb-4"
                style={{ overflow: "unset" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className="p-4">
                <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="./img/become-a-merchant/3.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  <h6 className="">Mayor of Venue (COMING SOON)</h6>
                  <p className="mt-3">
                    Discover the majesty of becoming a 'Mayor'! Sports
                    facilities are already utilizing this title to recognize
                    dedicated and frequent players. We're presenting a unique
                    chance for the First Official Mayor of Bayview Golf Club: a
                    FREE round! To become Mayor, simply check in with #playapp.
                    As Mayor, you will receive a FREE cold drink each time you
                    enter the clubhouse to play golf!
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 wrap-service11-box">
              <div
                className="card benefitCard card-shadow border-0 mb-4"
                style={{ overflow: "unset" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className="p-4">
                <div className="icon-space">
                      <div className="icon-round text-center d-inline-block rounded-circle bg-success-gradiant">
                        <img
                          className="icon-d"
                          src="./img/become-a-merchant/4.svg"
                          alt=""
                          style={{ paddingBottom: "14px" }}
                        />
                      </div>
                    </div>
                  <h6 className="">Leadership Award</h6>
                  <p className="mt-3">
                    Get ready for 'Saturday Night High Score'! Each week, the
                    highest scorers from each city will be named champions. The
                    finalist will receive a special badge and enjoy 7 days of
                    glory.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row py-5 badgescontent">
            <div className="col-lg-12">
              <hr style={{ color: "green", height: "3px" }}></hr>

              <h1 class="pb-3" style={{ fontSize: "32px" }}>
                Prizes Distribution Method
              </h1>

              <p>
                To unlock a gift, users must first earn the required points.
                Each award has a limitation on how many times it may be
                unlocked. Once this limit is reached, no other users will be
                able to acquire that reward, even if they have the necessary
                points.
              </p>
              <h1 class="pb-3" style={{ fontSize: "32px" }}>
                Prize Pool:
              </h1>

              <p>
                We are introducing a new interesting feature: a prize pool for
                match tickets. There is one ticket available for users to unlock
                with a certain quantity of points. If more than one user wants
                the ticket, Playapp will conduct a lucky draw. The lucky user
                will win the ticket, while the others will give up both the
                ticket and the points they used to unlock it
              </p>
              <h1 class="pb-3" style={{ fontSize: "32px" }}>
                Mayor of Venue (COMING SOON)
              </h1>
              <p>
                Discover the majesty of becoming a 'Mayor'! Sports facilities
                are already utilizing this title to recognize dedicated and
                frequent players. We're presenting a unique chance for the{" "}
                <b> First Official Mayor of Bayview Golf Club:</b> a FREE round!
                To become Mayor, simply check in with #playapp. As Mayor, you
                will receive a FREE cold drink each time you enter the clubhouse
                to play golf!
              </p>

              <h1 class="pb-3" style={{ fontSize: "32px" }}>
                Leadership Award:
              </h1>
              <p>
                Get ready for 'Saturday Night High Score'! Each week, the
                highest scorers from each city will be named champions. The
                finalist will receive a special badge and enjoy 7 days of glory.
              </p>
            </div>
          </div> */}
        </div>
        {/* <div className="row mt-3">
                <div
                  className="col-lg-6 order-2 order-md-1"
                  style={{ alignSelf: "center" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <h3 style={{ fontSize: "3rem", fontWeight: "700" }}>
                    Mission:
                  </h3>
                  <p>Revolutionizing the Sports and Entertainment Experience</p>
                  <p>
                    Our mission is to transform the way people interact with
                    sports and entertainment, making it more accessible by
                    breaking down barriers and making sports activities
                    available to all, connecting people and building
                    communities, providing incentives and recognition for
                    achievements and progress.
                  </p>
                  <p>
                    Additionally, we aim to enhance the well-being and
                    performance of players by providing sports insights and
                    analytics to improve skills and strategy and offering
                    personalized health solutions for optimal performance.
                  </p>
                </div>
                <div
                  className="col-lg-6 order-1 order-md-2"
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <img
                    src="../img/about/Group 177.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  />
                </div>
              </div> */}
      </div>{" "}
    </div>
  );
};

export default Badges;
