import React from "react";
import Hero from "./Hero";
import Find from "./Find";
import Offer from "./Offer";
import Booking from "./Booking";
import "./home.css";
import VideoSection from "./VideoSection";
import DocumentMeta from "react-document-meta";

const Home = () => {
  const currentURL = window.location.href;
  const meta = {
    title: "The First Incentivized Ecosystem For The Sports And Fitness Community",
    description:
      "Your favorite ground booking in World is just one click away with PlayApp. You can play indoor and outdoor games effortlessly by eliminating the call barrier.",
    canonical: currentURL,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Online Ground booking in Lahore, Indoor and outdoor games, playgrounds booking in Lahore, Find playgrounds in Lahore, Book playgrounds in Lahore, sports grounds near me, book sports grounds, sports grounds, playgrounds",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div>
        <div className="main-header">
          <Hero />
        </div>
        <VideoSection />
        <Find />
        <Offer />
        <Booking />
      </div>
    </DocumentMeta>
  );
};

export default Home;
