// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';

// const firebaseConfig = {
//     apiKey: "AIzaSyCyxhneI64b9rLfSMqYMtoX3_eGsDYrIOQ",
//     authDomain: "playapp-b2c90.firebaseapp.com",
//     projectId: "playapp-b2c90",
//     storageBucket: "playapp-b2c90.appspot.com",
//     messagingSenderId: "316447679676",
//     appId: "1:316447679676:web:f706de33a2dc31d7b49cb9",
//     measurementId: "G-89D1ZVX5SH"
//   };


  const firebaseConfig = {
    apiKey: "AIzaSyDRvZVsV5FohAsRPaANFYCvUa24mPo8ET0",
    authDomain: "playapp-economy.firebaseapp.com",
    projectId: "playapp-economy",
    storageBucket: "playapp-economy.appspot.com",
    messagingSenderId: "498288453632",
    appId: "1:498288453632:web:735b2a1e81a7279ac050e5",
    measurementId: "G-2CKNQVC19W"
  
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, onAuthStateChanged };
