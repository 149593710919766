import { Route, HashRouter as BrowserRouter, Switch } from "react-router-dom";
import Footer from "./Components/Layout/Footer";
import Hero from "./Components/Layout/Hero";
import Home from "./Components/Home/Home";
import Terms from "./Components/Terms/Term";
import HowitWorks from "./Components/HowItWorks/HowitWorks";
import Contact from "./Components/Contact/ContactUs";
import About from "./Components/About/AboutUs";
import Merchant from "./Components/Merchant/Merchant";
import BlogListing from "./Components/Blogs/Landing/Landing";
import BlogDetail from "./Components/Blogs/BlogDetails/BlogDetails.jsx";
import AvanueList from "./Components/Avanue/AvanueList.jsx";
import BookVenue from "./Components/Avanue/BookVenue/BookVenue";
import Faq from "./Components/FAQ/Faq.jsx";

// import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./Components/ScrollToTop.js";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Venuesdetails from "./Components/Avanue/Venuesdetails.jsx";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import BlackHeader from "./Components/Layout/BlackHeader";
import PricingAndRefund from "./Components/Pricing-and-refund/PricingAndRefund.jsx";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions.jsx";
import WhitePaper from "./Components/WhitePaper/WhitePaper.jsx";
import Badges from "./Components/Badges/Badges.jsx";
import AiSportsHealth from "./Components/AI sports Health/AiSportsHealth.jsx";
import Signup from "./Components/LoginSignup/Signup.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";

// import RegistrationForm from "./Components/LoginSignup/Login.jsx";
// import SponseredVenuesdetails from "./Components/Home/Layout/SponseredVenuesdetails.jsx";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const clientId =
  "748607149384-ikt4qn2lqv8omacg6j8nrrq66o1h9p7u.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={clientId}>


    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        {/* <CustomHeader /> */}
        <Switch>
          <div className="Wrapper">
            <Route exact path="*" component={BlackHeader} />
            <Route exact path="/" component={Home} />
            <Route exact path="/terms-and-conditions" component={Terms} />
            <Route
              exact
              path="/book-playgrounds"
              component={HowitWorks}
            />
            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/faq" component={Faq} />
            <Route
              exact
              path="/about-us"
              component={About}
            />
            {/* <Route exact path="/merchant-account" component={Merchant} /> */}
            <Route exact path="/find-your-ground" component={AvanueList} />
            <Route
              exact
              path="/blog-listing-sports-news"
              component={BlogListing}
            />
            <Route exact path="/blog-detail/:id" component={BlogDetail} />
            <Route exact path="/AI-Sport-Health" component={AiSportsHealth} />
            <Route exact path="/badges" component={Badges} />
            {/* <Route exact path="/Login" component={RegistrationForm} /> */}
            <Route exact path="/Signup" component={Signup} />
            {/* <Route
              exact
              path="/sponservenues/:id"
              component={SponseredVenuesdetails}
            /> */}

            <Route exact path="/venues-detail" component={Venuesdetails} />
            <Route exact path="/pricing-refund" component={PricingAndRefund} />

            <Route exact path="/book-venues" component={BookVenue} />
            <Route exact path="/White-Paper" component={WhitePaper} />
            <Route exact path="/Sign-up" component={Signup} />
            {/* <Route exact path="/Faq" component={Faq} /> */}

            {/* <Route exact path="*" component={Hero} /> */}
            <Route exact path="*" component={Footer} />
          </div>
        </Switch>
      </BrowserRouter>
    </Provider>
    </GoogleOAuthProvider>

  );
}

export default App;
