import React from "react";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <section className="HeroUpdate">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Hero-Content wm-md-100 gap-24">
              <div className="Content">
                <div>
                  <TypeAnimation
                    sequence={[
                      "The First Incentivized Ecosystem For The Sports And Fitness Community",
                      100,
                    ]}
                    wrapper="h4"
                    speed={200}
                    repeat={Infinity}
                  />
                  {/* <h4>
                    The First Incentivized Ecosystem For The Sports And Fitness
                    Community
                  </h4> */}
                </div>
                <div>
                  <p>
                    It offers substantial benefits along with gift vouchers for
                    added value.
                  </p>
                </div>
              </div>
              <div class="group-btn">
                <a class="bg-btn" href="#/book-playgrounds">
                  Get Started
                </a>
                <a class="outline-btn" href="#/contact-us">
                  Contact us
                </a>
              </div>
              <div className="banner">
                <img src="./img/herobanner.png" />
              </div>
              <div className="join">
                <img src="./img/join.png" />
              </div>
              <div className="hero-bottom-content">
                <ul className="list-inline">
                  <li>earn scores</li>
                  <li>|</li>
                  <li>achieve ranks</li>
                  <li>|</li>
                  <li>collect badges</li>
                  <li>|</li>
                  <li>redeem prizes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    // <div className="hero">
    //   <div className="container">
    //     <div className="row">
    //       <div
    //         className="col-md-12 col-lg-6"
    //         data-aos="fade-right"
    //         data-aos-duration="2000"
    //       >
    //         <div className="content">
    //           <h1>
    //             The First Incentivized Ecosystem For The Sports And Fitness
    //             Community
    //           </h1>
    //           <h2>PlayApp makes sports engagement fun and rewarding.</h2>
    //           <p>
    //             Earn scores, achieve ranks, collect badges, and redeem for
    //             prizes.{" "}
    //           </p>
    //           <div className="group-btn">
    //             <Link to="/book-playgrounds" className="bg-btn">
    //               Get Started
    //             </Link>
    //             <Link to="/contact-us" className="outline-btn">
    //               Contact us
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-12 col-lg-6">
    //         <div className="banner">
    //           <img src="./images/All.svg" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Hero;
