export default function TermsBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center MerchantBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>PlayApp Your #1 Sport Navigator</h2>
        </div>
      </section>
    </>
  );
}
