export default function AboutBanner() {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center AboutBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>About Us</h2>
          <h6>PlayApp takes your sports to the next level</h6>
        </div>
      </section>
    </>
  );
}
