import React from "react";

const PricingAndRefund = () => {
  return (
    <>
      <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>PlayApp Privacy Policy</h2>
        </div>
      </section>

      <div>
        {/* Content */}
        <section id="TAC" className="TermsPage">
          <div className="container mt-5">
            <div className="row text-center">
              <div className="col-12">
                <h3
                  className="heading"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  Privacy Policy
                </h3>
              </div>
            </div>
            <hr style={{ color: "green", height: "3px" }} />
            <div className="row " data-aos="fade-up" data-aos-duration="1500">
              <p>
                We use cookies to provide the best experience. By continuing to
                use our website, you agree to our cookies policy.
              </p>
              <p>
                <em>Last updated: 20 May 2024</em>
              </p>

              <p>
                <strong>PlayApp Economy</strong> is a company incorporated in
                Canada (hereafter referred to as “PlayApp”, “we”, or “us”).
              </p>
              <p>
                This Privacy Policy applies to your use of the PlayApp mobile
                application (the “PlayApp”) which includes the PlayApp
                Marketplace (“Marketplace”) and uses the PlayApp reward scores
                (“Score(s)”) ("SCRS"), any website operated by PlayApp
                (including{" "}
                <a href="http://playappeconomy.com">
                  http://playappeconomy.com
                </a>
                ) (the “PlayApp Website(s)”), and the services we provide that
                are accessible via any of them (the “Services”). This Privacy
                Policy outlines how we collect, store, and process any personal
                data you provide to us.
              </p>
            </div>

            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>MINIMUM AGE REQUIREMENT</h5>
              <p>
                You must be 13 years or older to use the Services. Please do not
                use the Services or provide us with any personal information if
                you are under 13 years of age.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>INFORMATION WE MAY COLLECT FROM YOU</h5>
              <p>
                We may collect or ask you to provide personal information
                including (but not limited to) your name, mobile phone number,
                email address, password, identification credentials, your
                contacts, biographical details, photographs, and/or payment
                information.
              </p>

              <p>
                We also automatically collect information about your use of the
                Services and your devices, including:
              </p>
              <div>
                <ul>
                  <li>
                    <strong>Technical Data:</strong> IP address, browser type,
                    internet service provider, device identifiers, login
                    information, time zone setting, browser plug-in types and
                    versions, preferred language, activities, operating system,
                    platform, and geographical location.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> URLs, clickstream data, pages
                    viewed and searched for, page response times, visit length,
                    referral source/exit pages, page interaction information,
                    date and time pages are accessed, website navigation, and
                    search terms used.
                  </li>
                  <li>
                    <strong>Transaction Data:</strong> Purchases made through
                    the PlayApp or PlayApp Websites to understand your
                    preferences for tailored marketing.
                  </li>
                </ul>
              </div>
              <p>
                We may also collect information about your location and physical
                movements, with your consent, for the PlayApp to function
                properly. This can be turned on and off in your device settings,
                but disabling it will prevent tracking and conversion of
                movement into Scores.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>USES OF YOUR PERSONAL DATA</h5>
              <p>
                We will only use your personal data if we have a legal basis for
                doing so. Our uses include:
              </p>
              <div>
                <ul>
                  <li>Providing, customizing, and improving the Services.</li>
                  <li>
                    Processing transactions and providing customer support.
                  </li>
                  <li>Communicating updates and marketing information.</li>
                  <li>Analyzing usage to improve the Services.</li>
                  <li>Ensuring security and preventing fraud.</li>
                  <li>Complying with legal obligations.</li>
                </ul>
              </div>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>SHARING YOUR PERSONAL DATA</h5>
              <p>We may share your personal data with:</p>
              <div>
                <ul>
                  <li>
                    <strong>Other Users:</strong> Certain profile information
                    may be visible to other users.
                  </li>
                  <li>
                    <strong>Service Providers:</strong> Third-party providers
                    performing services on our behalf.
                  </li>
                  <li>
                    <strong>Business Partners:</strong> Trusted partners for
                    marketing purposes, with your consent.
                  </li>
                  <li>
                    <strong>Legal Authorities:</strong> If required by law or to
                    protect rights and safety.
                  </li>
                  <li>
                    <strong>In Business Transactions:</strong> If involved in a
                    merger, acquisition, or sale.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>INTERNATIONAL TRANSFERS</h5>
              <p>
                We may transfer your personal data outside of your country. If
                you are in Canada or the USA, this may include countries without
                the same level of data protection. We will ensure adequate
                safeguards are in place for such transfers.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>DATA RETENTION</h5>
              <p>
                We will retain your personal data only as long as necessary for
                the purposes outlined in this policy, or as required by law.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>DATA SECURITY</h5>
              <p>
                We take reasonable measures to protect your personal data from
                unauthorized access and use.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>COOKIES</h5>
              <p>
                We use cookies on the PlayApp and PlayApp Websites for various
                purposes, including analytics and advertising. More details are
                available in Annex 2.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>YOUR RIGHTS</h5>
              <p>Depending on your location, you may have the right to:</p>
              <div>
                <ul>
                  <li>Opt out of marketing communications.</li>
                  <li>Access and update your personal information.</li>
                  <li>Request data deletion.</li>
                  <li>Object to certain data uses.</li>
                  <li>Receive data in a portable format.</li>
                  <li>Withdraw consent for data processing.</li>
                </ul>
              </div>
              <p>
                For any requests, please contact us at{" "}
                <a href="mailto:privacy@playappeconomy.com">
                  privacy@playappeconomy.com
                </a>{" "}
                with the subject line “enquiry”.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>COMPLAINTS</h5>
              <p>
                For complaints about our data processing, contact us at{" "}
                <a href="mailto:privacy@playappeconomy.com">
                  privacy@playappeconomy.com
                </a>
                . You can also lodge a complaint with your national data
                protection authority.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>LINKS TO THIRD-PARTY SITES</h5>
              <p>
                Our Services may link to third-party websites. We are not
                responsible for their content or privacy practices. Please
                review their policies before providing personal data.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>CHANGES TO THIS PRIVACY POLICY</h5>
              <p>
                We may update this Privacy Policy periodically. Changes will be
                posted on the PlayApp and PlayApp Websites, and may be emailed
                to you. Please review the policy regularly.
              </p>
            </div>
            <div
              className="row py-3"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h5>CONTACT</h5>
              <p>
                Questions and requests regarding this Privacy Policy should be
                addressed to{" "}
                <a href="mailto:privacy@playappeconomy.com">
                  privacy@playappeconomy.com
                </a>{" "}
                with the subject line “enquiry”.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PricingAndRefund;
