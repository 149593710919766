import React from "react";

const Find = () => {
  return (
    <div style={{ background: "black" }}>
      <div className="container">
        <div className="play-find ">
          <div className="row align-items-center">
            <div className="col-lg-6"  data-aos="fade-right"  data-aos-duration="2000">
              <h2 className="pb-3">Review</h2>
              <p>
              Users can provide direct reviews based on past experiences or what they've heard, earning scores (SCRS) and prizes.
              </p>
    <br></br>
              <h2 className="pb-3">Check-In</h2>
              <p>
              Users can also check in to venues during a visit to assess the amenities, issues, infrastructure, capacity, and spaciousness. This check-in option offers more scores and rewards.
              </p>
            </div>
            <div className="col-lg-6 text-end"  data-aos="fade-left"  data-aos-duration="2000">
              <img src="./img/review.png" alt="mockup" />
            </div>
          </div>
        </div>
        <div className="play-find ">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1"  data-aos="fade-right"  data-aos-duration="2000">
              <img src="./img/iphone.png" alt="mockup" />
            </div>
            <div className="col-lg-6 order-1 order-lg-2"  data-aos="fade-left"  data-aos-duration="2000">
              <h2 className="pb-3">Earn Gifts and Rewards </h2>
              <p>
              Users will earn rewards based on their engagement with venues, such as reviews and check-ins. The more a user engages with a venue, the more scores they will earn. Upon reaching certain score thresholds, users can rank up, unlock badges, and redeem exciting gifts and prizes (with scores being deducted). Additionally, users will be eligible for various draws and exciting prizes.
              </p>
            </div>
          </div>
        </div>
        <div className="play-find" style={{marginTop:"-100px"}}>
          <div className="row align-items-center">
            <div className="col-lg-6"  data-aos="fade-right"  data-aos-duration="2000">
              <h2 className="pb-3">Enjoy Benefits of Badges </h2>
              <p>
              Users will receive badges as a form of recognition for their activities at various venues. These badges signify different levels of achievement and engagement within the PlayApp community, serving as a visible representation of the user's dedication. Additionally, earning badges unlocks various prizes, gifts, and rewards, and helps users rank up and appear on the leaderboard.
              </p>
            </div>
            <div className="col-lg-6 text-end" data-aos="fade-left"  data-aos-duration="2000">
              <img src="../img/iPhone16.png" alt="mockup" />
            </div>
          </div>
        </div>
        {/* <div className="talk">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="pb-5">Let´s Talk Sports</h1>
                    <img data-aos="flip-up"  data-aos-duration="3000" className="phone_bottom w-100 pt-5" src="../../../img/iPhone 14 Pro.png" alt="mockup" />
                </div>
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default Find;
