import React from 'react'

const WhitePaper = () => {
  return (
    <div>
       <section className="d-flex justify-content-center align-items-center BlogBanner Banner">
        <div className="" data-aos="fade-up" data-aos-duration="1500">
          <h2>White Paper</h2>
          <h6>
          PlayApp is the  top search and booking site for
            recreational sports.
          </h6>
        </div>
      </section>
      
    </div>
  )
}

export default WhitePaper
