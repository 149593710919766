import React, { Component } from "react";
import AboutBanner from "./Layout/AboutBanner";
import WhoWeAre from "./Layout/WhoWeAre";
import DocumentMeta from "react-document-meta";

class AboutUs extends Component {
  render() {
    const currentURL = window.location.href;
    const meta = {
      title: "Sports Ground Booking in World | Cricket Ground Booking",
      description:
        "PlayApp is best for sports ground booking in World. Moreover, Cricket Ground Booking is also available. It ensures your 100% tension-free booking.",
      canonical: currentURL,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "cricket ground booking, Find playgrounds, Book playgrounds, snooker clubs, women's sports complex, video games, outdoor and indoor games for adults, sports ground booking, sports grounds",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <div>
          <AboutBanner />
          {/* about us */}
          <section id="AboutUs">
            <div className="container">
              <div className="container pt-5 pb-5">
                <div className="row">
                  <div className="col-lg-12">
                    <h1
                      style={{ fontSize: "40px" }}
                      className="text-center pb-4"
                    >
                      The Unstoppable Entertainment
                    </h1>
                    <p className="text-center">
                      Playapp lets you watch the best live streaming of any
                      match and win prizes. It also lets you discover new
                      places, and friends by using the app to gain prizes and
                      recognition! Our primary mechanics is to entertain users
                      with the best live streaming of sports events and
                      entertainment. Earn points for completing challenges,
                      watching live streaming, checking in, and evaluating
                      venues, and earn badges and levels as you earn specified
                      scores and complete challenges. Compare your progress to
                      others on city, country, and global leaderboards, and use
                      your points to get unique discounts, free access, and
                      premium services. Additionally, complete daily, weekly,
                      and monthly challenges to earn additional points and
                      incentives. The more you engage, the more you get
                      rewarded!
                    </p>
                    {/* <p className="text-center">
                      You can browse several possibilities for sports ground
                      booking in Lahore on PlayApp. You can search for the places
                      near you or search for your favorite place name. choose
                      the time and pay by any medium (bank account or jazz
                      cash). There is no need to be concerned about hassles or
                      doubts because PlayApp has made the entire process easy for
                      your needs. So, if you want to ensure a hassle-free
                      experience for your sports ground booking in Lahore,
                      PlayApp is the platform to use.
                    </p> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h2
                      style={{ fontSize: "40px" }}
                      className="text-center pb-4"
                    >
                      Vision
                    </h2>
                    <p className="text-center">
                      Empowering a Global Sports and Entertainment Community
                    </p>
                    <p className="text-center">
                      Our vision is to become the premier destination for sports
                      and entertainment fans worldwide, uniting 1 billion users
                      globally. We aim to create a dynamic community where users
                      can connect with like-minded individuals, share
                      experiences and passions, access exclusive content and
                      events, engage in the best discussions, and enjoy seamless
                      interactions and connections. By achieving this vision, we
                      will revolutionize the way sports and entertainment
                      enthusiasts interact, creating a truly global and
                      inclusive community that rises above borders and
                      boundaries.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div
                  className="col-lg-6 order-2 order-md-1"
                  style={{ alignSelf: "center" }}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <h3 style={{ fontSize: "3rem", fontWeight: "700" }}>
                    Mission:
                  </h3>
                  <p>Revolutionizing the Sports and Entertainment Experience</p>
                  <p>
                    Our mission is to transform the way people interact with
                    sports and entertainment, making it more accessible by
                    breaking down barriers and making sports activities
                    available to all, connecting people and building
                    communities, providing incentives and recognition for
                    achievements and progress.
                  </p>
                  <p>
                    Additionally, we aim to enhance the well-being and
                    performance of players by providing sports insights and
                    analytics to improve skills and strategy and offering
                    personalized health solutions for optimal performance.
                  </p>
                </div>
                <div
                  className="col-lg-6 order-1 order-md-2"
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <img
                    src="../img/about/sport 1.png"
                    alt=""
                    style={{ width: "100%" }}
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <WhoWeAre /> */}
          {/* <section id="ceo" className="mt-5 ">
            <div className="container mt-5 mb-5">
              <div className="row">
                <div
                  className="col-md-4 ceo-img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="../img/about/hamza.jpeg" alt="" />
                </div>
                <div
                  className="col-md-8 ceo-text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="content-ceo">
                    <h2 className="py-1">Director’s Vision:</h2>
               
                    <blockquote className="quote-card">
                      <p>
                        My visionary ambition to introduce "PlayApp," the
                        first-ever sports ground booking brand in Pakistan, is
                        an indication of a forward-thinking approach and
                        commitment to revolutionizing sports in the country.
                        With this innovative concept, I aim to create a dynamic
                        space where people of all ages and backgrounds can come
                        together to engage in their favorite sports and athletic
                        activities. PlayApp is not just a facility; it
                        represents a vision of promoting a healthier, more
                        active lifestyle among Pakistanis and nurturing sporting
                        talent. By bringing this innovative concept to life, I
                        aspire to fill a significant gap in the local sports
                        infrastructure and provide a platform that will
                        encourage both unprofessional and professional athletes
                        to pursue their passions and dreams. This visionary
                        initiative has the potential to leave a lasting legacy
                        in Pakistan's sporting history.
                      </p>
                    </blockquote>
                    <h6>Hamza Yaseen</h6>
                    <p>Director</p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </DocumentMeta>
    );
  }
}

export default AboutUs;
