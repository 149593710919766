import React from "react";
import Counter from "./Component/Counter";

const Booking = () => {
  return (
    <div style={{ background: "black" }}>
      <div className="container">
        <div className="community" style={{paddingTop:"170px"}}>
          <div className="inner-community">
            <div className="row">
              <div className="col-lg-5">
                <div
                  className="home_pg"
                  // style={{ position: "relative", bottom: "170px" }}
                >
                  <img
                    className="mockups w-100"
                    // style={{paddingBottom:"30px"}}

                    src="./img/phone.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="community-content">
                  <h2>Join the PLAYAPP Community for Easy Booking</h2>

                  <div className="row justify-content-center align-item-center my-4">
                    <div className="row">
                      <div className="col-sm-12 col-lg-6">
                        <Counter number={16} title="Countries" />
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <Counter number={156} title="Cities" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-12 col-lg-6">
                        <Counter number={100000} title="Grounds" />
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <Counter number={15} title="Sports" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex gap-5 w-100 justify-content-start flex-wrap">
                    <div className="apps">
                      <img src="../../../img/Image.png" alt="icon" />
                      <div>
                        <h4>Apple App Store</h4>
                        <h3>
                          4.6 <span>(273k RATINGS)</span>
                        </h3>
                      </div>
                    </div>
                    <div className="apps">
                      <img src="../../../img/Image (1).png" alt="icon" />
                      <div>
                        <h4>GOOGLE PLAY</h4>
                        <h3>
                          4.4 <span>(1.8M RATINGS)</span>
                        </h3>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div style={{ background: "black" }}>
    //   <div className="container">
    //     <div className="community" style={{ paddingTop: "125px" }}>
    //       <div className="inner-community">
    //         <div className="row">
    //           <div className="col-lg-5">
    //             <div
    //               className="d-flex justify-content-center home_pg "
    //               style={{ position: "relative", bottom: "170px" }}
    //             >
    //               <img
    //                 className="mockups "
    //                 // style={{paddingBottom:"30px"}}

    //                 src="./img/phone.png"
    //                 alt=""
    //               />
    //             </div>
    //           </div>
    //           <div className="col-lg-7">
    //             <div
    //               style={{
    //                 flexDirection: "column",
    //                 placeItems: "center",
    //                 height: "100%",
    //               }}
    //               className="d-flex justify-content-center place-items-center flex-direction-column p-3 p-sm-5 p-lg-0"
    //             >
    //               <h2>Join the PLAYAPP Community for Easy Booking</h2>

    //               <div className="row justify-content-center align-item-center mt-4">
    //                 <div className="row">
    //                   <div className="col">
    //                     <Counter number={16} title="Countries" />
    //                   </div>
    //                   <div className="col">
    //                     <Counter number={156} title="Cities" />
    //                   </div>
    //                 </div>
    //                 <div className="row mt-3">
    //                   <div className="col">
    //                     <Counter number={100000} title="Grounds" />
    //                   </div>
    //                   <div className="col">
    //                     <Counter number={15} title="Sports" />
    //                   </div>
    //                 </div>
    //               </div>
    //               {/* <div className="d-flex gap-5 w-100 justify-content-start flex-wrap">
    //                 <div className="apps">
    //                   <img src="../../../img/Image.png" alt="icon" />
    //                   <div>
    //                     <h4>Apple App Store</h4>
    //                     <h3>
    //                       4.6 <span>(273k RATINGS)</span>
    //                     </h3>
    //                   </div>
    //                 </div>
    //                 <div className="apps">
    //                   <img src="../../../img/Image (1).png" alt="icon" />
    //                   <div>
    //                     <h4>GOOGLE PLAY</h4>
    //                     <h3>
    //                       4.4 <span>(1.8M RATINGS)</span>
    //                     </h3>
    //                   </div>
    //                 </div>
    //               </div> */}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Booking;
