export default function Ranks() {
  return (
    <>
      <section className="Product" id="product">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="headercontent">
                <div>
                  <h4>Ranks Based on Scores</h4>
                  <p>
                    Users will be awarded with Ranks based on the scores (earn
                    online) system given above. Here are the complete rank
                    system
                  </p>
                </div>
              </div>
              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank1.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Newbie</h4>
                      <ul className="list-inline">
                        <li>0-499 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>1x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You lace up your shoes and step out into the unknown,
                        eager to explore new venues and discover hidden gems.
                        Every check-in and review brings you closer to unlocking
                        the secrets of the sports and fitness community.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank2.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Apprentice</h4>
                      <ul className="list-inline">
                        <li>500-999 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>1.25x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You stumble upon a trendy fitness studio, and the
                        instructor shares tips and tricks to enhance your
                        workout. You realize that every venue has a story to
                        tell, and every review helps others find their new
                        favorite spot.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank3.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Rookie</h4>
                      <ul className="list-inline">
                        <li>999-1999 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>1.5x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You conquer a challenging hike, and the breathtaking
                        view from the summit is your reward. You can't wait to
                        share your experience and help others find their next
                        adventure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank4.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Dynamo</h4>
                      <ul className="list-inline">
                        <li>2000-3999 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>1.75x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You're a fitness force to be reckoned with, exploring
                        new venues and trying new activities. Every check-in and
                        review earns you scores, and you're determined to reach
                        the top of the leaderboard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank5.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Maestro</h4>
                      <ul className="list-inline">
                        <li>4000-9999 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>2x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You're a seasoned explorer, with a wealth of knowledge
                        and experience. You've discovered hidden gems and shared
                        your expertise with the community. Your reviews are
                        sought after, and your scores are the envy of many.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="content">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="firstheading">
                      <img src="/img/rank6.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="secondheading">
                      <h4>Elite</h4>
                      <ul className="list-inline">
                        <li>10,000-49,999 scores</li>
                        <li>
                          <img src="/img/blackstaric.svg" />
                        </li>
                        <li>2.5x</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="thirdheading">
                      <p>
                        You're a legend in the sports and fitness community,
                        with a reputation for discovering the best venues and
                        sharing your expertise. Your name is synonymous with
                        adventure and fitness, and your scores are the stuff of
                        legend
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
